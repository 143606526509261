<template>
  <a-row>
    <a-col v-for="current in card" class="card-container">
      <div class="card" @click="cardClick(current)">
        <div class="app-card">
          <icon-font v-if="current.hasCollect ===1" type="icon-shoucang3" title="取消收藏" class="icon icon-sc"
                     @click.stop="cancelCollection(current)"/>
          <icon-font type="icon-shoucang1" v-else title="收藏" class="icon icon-sc" @click.stop="collection(current)"/>
          <img :src="'/PIC/' + current.iconName" style="width: 22px;"/>
          <!--          <img :src="current.icon" style="width: 22px;"/>-->
          <span class="title">{{ current.name }}</span>
        </div>
        <a-divider style="margin: 15px 0 5px 0;"/>
        <div class="content">{{ current.info }}</div>
      </div>
    </a-col>
    <LoginModal v-if="login" :modalVisible.sync="login" :reload="false"/>
  </a-row>
</template>


<script>
import {addCollection, removeCollection} from "@/api/center";

export default ({
  components: {
    LoginModal: () => import("@/components/Login/LoginModal.vue"),
  },
  props: {
    card: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      login: false
    }
  },
  methods: {
    cardClick(cur) {
      this.$emit('cardClick', cur)
    },
    collection(cur) {
      if (this.$store.getters.token) {
        addCollection(cur.id).then(res => {
          if (res.success) {
            this.card.forEach(item => {
              if (item.id === cur.id) {
                item.hasCollect = 1
              }
            })
            this.$message.success("收藏成功")
          }
        })
      } else {
        this.login = true
        this.$message.warn("请先扫码登录哦")
      }
    },
    cancelCollection(cur) {
      removeCollection(cur.id).then(res => {
        if (res.success) {
          this.card.forEach(item => {
            if (item.id === cur.id) {
              item.hasCollect = 0
            }
          })
          this.$message.success("取消成功")
          this.$emit('cancelCollection')
        }
      })
    }
  }
})
</script>

<style scoped lang="scss">
//.icon-sc {
//  position: absolute;
//  font-size: 20px;
//  right: 15px;
//}
</style>